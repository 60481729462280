import * as reduxModule from 'redux';
import { applyMiddleware, compose, createStore } from 'redux';
import createReducer from './reducers/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import '@mantine/core/styles.css';
import { thunk } from 'redux-thunk';

/*
Fix for Firefox redux dev tools extension
https://github.com/zalmoxisus/redux-devtools-instrument/pull/19#issuecomment-400637274
*/
reduxModule.__DO_NOT_USE__ActionTypes.REPLACE = '@@redux/INIT';

const persistConfig = {
	key: 'root',
	storage,
	whitelist: [], // Add the keys of reducers you want to persist
};

const composeEnhancers =
	process.env.NODE_ENV !== 'production' &&
	typeof window === 'object' &&
	window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
		? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
		: compose;

const enhancer = composeEnhancers(applyMiddleware(thunk));

const persistedReducer = persistReducer(persistConfig, createReducer());

const store = createStore(persistedReducer, enhancer);

store.asyncReducers = {};

// Modify the injectReducer function to handle persistence
export const injectReducer = (key, reducer) => {
	if (store.asyncReducers[key]) {
		return false;
	}
	store.asyncReducers[key] = reducer;
	store.replaceReducer(
		persistReducer(persistConfig, createReducer(store.asyncReducers))
	);
	return store;
};

// Persist the store
export const persistor = persistStore(store);

export default store;
