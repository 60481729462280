import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useParams, useNavigate } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import DetailPost from '../Redux/actions/DetailPost';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import ReactPlayer from 'react-player';
import { Helmet } from 'react-helmet';

const Detail = ({ DetailPost, detail, posts }) => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);

    const filteredPosts = posts?.filter(
        (post) =>
            post?.data?.post_hint === 'image' || post?.data?.post_hint === 'hosted:video'
    ) || [];

    console.log(filteredPosts)
    // Find current post index
    const currentIndex = filteredPosts?.findIndex((post) => post.data.id === id) || 0;

    useEffect(() => {
        const fetchDetail = async () => {
            setLoading(true);
            await DetailPost(id);
            setLoading(false);
        };
        fetchDetail();
    }, [id, DetailPost]);

    const closeButton = () => {
        navigate(-1);
    };

    const downloadMedia = async () => {
        const mediaUrl = detail?.data?.data?.url_overridden_by_dest;

        if (mediaUrl) {
            try {
                const response = await fetch(mediaUrl, { mode: 'cors' });
                const blob = await response.blob();
                const url = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = url;
                const fileName = detail?.data?.data?.title || 'downloaded-media';
                link.download = fileName;

                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);

                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error('Error downloading the media:', error);
            }
        } else {
            console.error('Invalid media URL');
        }
    };

    const goToPreviousPost = () => {
        if (filteredPosts && currentIndex > 0) {
            const previousPostId = filteredPosts[currentIndex - 1]?.data?.id;
            if (previousPostId) navigate(`/detail/${previousPostId}`);
        }
    };
    
    const goToNextPost = () => {
        if (filteredPosts && currentIndex < filteredPosts.length - 1) {
            const nextPostId = filteredPosts[currentIndex + 1]?.data?.id;
            if (nextPostId) navigate(`/detail/${nextPostId}`);
        }
    };

    return (
        <>
           <Helmet>
    <meta charSet="utf-8" />
    <title>{detail?.data?.data?.title || "Scrollway - Photos & Videos"}</title>
    <meta name="description" content={detail?.data?.data?.title || "Scrollway - Explore captivating photos and videos"} />
    <meta name="keywords" content="Scrollway, photos, videos, media, exploration" />
    <meta name="author" content="Scrollway - Photos & Videos" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="canonical" href={`https://scrollway.com/detail/${id}`} />

    {/* Open Graph / Facebook */}
    <meta property="og:type" content="website" />
    <meta property="og:url" content={`https://scrollway.com/detail/${id}`} />
    <meta property="og:title" content={detail?.data?.data?.title || "Scrollway - Photos & Videos"} />
    <meta property="og:description" content={detail?.data?.data?.title || "Scrollway - Explore captivating photos and videos"} />
    <meta property="og:image" content={detail?.data?.data?.url_overridden_by_dest || "https://scrollway.com/default-image.jpg"} />

    {/* Twitter */}
    <meta property="twitter:card" content="summary_large_image" />
    <meta property="twitter:url" content={`https://scrollway.com/detail/${id}`} />
    <meta property="twitter:title" content={detail?.data?.data?.title || "Scrollway - Photos & Videos"} />
    <meta property="twitter:description" content={detail?.data?.data?.title || "Scrollway - Explore captivating photos and videos"} />
    <meta property="twitter:image" content={detail?.data?.data?.url_overridden_by_dest || "https://scrollway.com/default-image.jpg"} />
</Helmet>
            {loading ? (
                <div className="loading">Loading...</div>
            ) : (
                <>
                    <div className="detailImage">
                        {detail?.data?.data?.post_hint === 'image' ? (
                            <LazyLoadImage src={detail?.data?.data?.url_overridden_by_dest} />
                        ) : (
                            detail?.data?.data?.post_hint === 'hosted:video' && (
                                <ReactPlayer
                                    url={detail?.data?.data?.media?.reddit_video?.hls_url?.replaceAll("&amp;", "&")}
                                    controls={true}
                                    width="100%"
                                    height="100%"
                                    playing={true}
                                    playIcon={true}
                                />
                            )
                        )}
                    </div>
                    <h1 className="detailTitle">{detail?.data?.data?.title}</h1>
                    <h2 className="detailSub">{detail?.data?.data?.subreddit}</h2>
                    <div className="closebutton" onClick={closeButton}>
                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                            <path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 0 0 203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path>
                        </svg>
                    </div>
                    <div className="">
                        <button onClick={goToPreviousPost} disabled={currentIndex <= 0} className="nav-button">
                            &lt; Previous
                        </button>
                        <button onClick={goToNextPost} disabled={currentIndex >= posts.length - 1} className="nav-button">
                            Next &gt;
                        </button>
                    </div>
                    <div className="detailbuttons">
                        <div className="download" onClick={downloadMedia}>
                            <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1.5em" width="1.5em" xmlns="http://www.w3.org/2000/svg">
                                <path fillRule="evenodd" d="M3 17a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm3.293-7.707a1 1 0 011.414 0L9 10.586V3a1 1 0 112 0v7.586l1.293-1.293a1 1 0 111.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clipRule="evenodd"></path>
                            </svg>
                        </div>
                    </div>
                </>
            )}
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        detail: state.detailpostReducers,
        posts: state?.homereducers?.data, // Assuming posts are stored in `postsReducers`
    };
};

const mapDisPatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            DetailPost,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDisPatchToProps)(Detail);
