import React, { useEffect, useState } from 'react';
import Media from '../components/Media';
import { connect } from 'react-redux';
import Homedata from '../Redux/actions/HomeData';
import { bindActionCreators } from 'redux';
import {Helmet} from "react-helmet";
import { debounce } from 'lodash';


const Home = ({ Homedata, home }) => {
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false); 

    const subs = 'ImaginaryTrees'

    useEffect(() => {

        let isMounted = true; // To track if the component is mounted

        /**
         * Fetches data from the subreddit specified in the `subs` variable.
         * Also takes the name of the last post in the `home` array and the current `page` as arguments.
         * Sets the `loading` state to `true` while the data is being fetched.
         * If the component is still mounted when the data is received, sets the `loading` state back to `false`.
         */
        const fetchData = async () => {
            setLoading(true);
            await Homedata(subs, home?.slice(-1)[0]?.data?.name, page);
            if (isMounted) { // Check if component is still mounted before setting state
                setLoading(false);
            }
        };
        fetchData();
        return () => {
            isMounted = false; // Cleanup function to set isMounted to false
        };
    }, [page, window.location.pathname]);

    useEffect(() => {
        const handleScroll = debounce(() => {
            if (
                window.innerHeight + document.documentElement.scrollTop >=
                document.documentElement.offsetHeight - 500 &&
                !loading
            ) {
                setPage((prevPage) => prevPage + 1);
            }
        }, 200); // Adjust the debounce delay as needed
    
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [loading]);


    return (
        <>
        <Helmet>
        <meta charSet="utf-8" />
                <title>Scrollway - Photos & Videos</title>
                <meta name="description" content="Scrollway - Photos & Videos" />
                <meta name="keywords" content="Scrollway - Photos & Videos" />
                <meta name="author" content="Scrollway - Photos & Videos" />
                <meta name="viewport" content="width=device-width, initial-scale=1.0"
                />
                <link rel="canonical" href="https://scrollway.com/" />
        </Helmet>
        <div className='mainContainer'>
            <Media home={home} />
            {loading && 
            <div className='loaderIcon'> 
          <svg width="50" height="50" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg" fill="currentColor">
          <circle cx="50" cy="50" r="35" stroke="currentColor" stroke-width="10" fill="none" opacity="0.2"/>
          <circle cx="50" cy="50" r="35" stroke="currentColor" stroke-width="6" fill="none" stroke-dasharray="164.93361431346415 56.97787143782138">
            <animateTransform 
              attributeName="transform" 
              type="rotate" 
              from="0 50 50" 
              to="360 50 50" 
              dur="1s" 
              repeatCount="indefinite" />
          </circle>
        </svg>
        </div>
         }
        </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        home: state?.homereducers?.data,
    };
};

const mapDisPatchToProps = (dispatch) => {
    return bindActionCreators(
        {
            Homedata,
        },
        dispatch
    );
};

export default connect(mapStateToProps, mapDisPatchToProps)(Home);