import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useDisclosure } from '@mantine/hooks';
import { Badge, Divider, Image, Input, List, Modal, NavLink } from '@mantine/core';
import { bindActionCreators } from 'redux';
import NavSearch from '../Redux/actions/NavSearch';
import { useNavigate } from 'react-router-dom';

const Navbar = ({ NavSearch, searchData }) => {
  const [search, setSearch] = useState('');
  const [opened, { open, close }] = useDisclosure(false);
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const navigate = useNavigate()

  useEffect(() => {
    if (opened) {
      // Delay focusing slightly to ensure modal is fully rendered
      setTimeout(() => {
        inputRef.current?.focus();
      }, 50); // Adjust timeout if necessary
    }
  }, [opened]);

  useEffect(() => {
    if (search.length > 2) {
      const debounceSearch = setTimeout(() => {
        try {
          NavSearch(search);
          setError(null);
        } catch (err) {
          setError('Failed to fetch search results.');
        }
      }, 300); // Debounce time of 300ms

      return () => clearTimeout(debounceSearch);
    }
  }, [search, NavSearch]);

  function formatNumber(num) {
    if (num >= 1e9) {
      return (num / 1e9).toFixed(1) + 'B';
    } else if (num >= 1e6) {
      return (num / 1e6).toFixed(1) + 'M';
    } else if (num >= 1e3) {
      return (num / 1e3).toFixed(1) + 'k';
    } else {
      return num?.toString();
    }
  }

  return (
    <div className='NavbarConainer'>
    

      <div className='logo' onClick={() => navigate('/')}>SROLLWAY</div>

      <div className='search'>
        <input placeholder='search' onClick={open} />
      </div>

      <div className='social'>
        <div>
          <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
            <path d="M512 378.7c-73.4 0-133.3 59.9-133.3 133.3S438.6 645.3 512 645.3 645.3 585.4 645.3 512 585.4 378.7 512 378.7zM911.8 512c0-55.2.5-109.9-2.6-165-3.1-64-17.7-120.8-64.5-167.6-46.9-46.9-103.6-61.4-167.6-64.5-55.2-3.1-109.9-2.6-165-2.6-55.2 0-109.9-.5-165 2.6-64 3.1-120.8 17.7-167.6 64.5C132.6 226.3 118.1 283 115 347c-3.1 55.2-2.6 109.9-2.6 165s-.5 109.9 2.6 165c3.1 64 17.7 120.8 64.5 167.6 46.9 46.9 103.6 61.4 167.6 64.5 55.2 3.1 109.9 2.6 165 2.6 55.2 0 109.9.5 165-2.6 64-3.1 120.8-17.7 167.6-64.5 46.9-46.9 61.4-103.6 64.5-167.6 3.2-55.1 2.6-109.8 2.6-165zM512 717.1c-113.5 0-205.1-91.6-205.1-205.1S398.5 306.9 512 306.9 717.1 398.5 717.1 512 625.5 717.1 512 717.1zm213.5-370.7c-26.5 0-47.9-21.4-47.9-47.9s21.4-47.9 47.9-47.9 47.9 21.4 47.9 47.9a47.84 47.84 0 0 1-47.9 47.9z"></path>
          </svg>
        </div>
      </div>

      <Modal
        opened={opened}
        onClose={close}
        title="Search"
        overlayProps={{
          backgroundOpacity: 0.55,
          blur: 3,
        }}
      >
        <Input ref={inputRef} placeholder='Search Here' value={search} onChange={(e) => setSearch(e.target.value)} />
        {error && <div className="error">{error}</div>}
        {
          searchData?.data?.length > 0 && <div className='resultCount'>Showing <strong>{searchData.data.length}</strong> results</div>
        }
        
        {searchData?.data?.map((post) => (
          <React.Fragment key={post.data.id}>
            
            <SearchResult post={post} formatNumber={formatNumber} />
            <Divider />
          </React.Fragment>
        ))}
      </Modal>
    </div>
  );
};

const SearchResult = ({ post, formatNumber }) => (
  <NavLink
    href={`${post.data.display_name_prefixed.split('/')[1]}`}
    label={post.data.title}
    description={post.data.public_description}
    leftSection={
      <Image
        className='searchImage'
        src={post.data.icon_img || post.data.community_icon?.replaceAll('&amp;', '&') || post.data.banner_background_image?.replaceAll('&amp;', '&')}
      />
    }
    rightSection={
      <div className='rightsectionsearch'>
        <div className='nsfwtag'>{post.data.over18 ? '18+' : ''}</div>
        <div className='subscount'>{formatNumber(post.data.subscribers)} subs</div>
      </div>
    }
  />
);

const mapStateToProps = (state) => {
  return {
    searchData: state.NavSearchReducers,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      NavSearch
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
